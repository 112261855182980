/* General styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f4f4f4;
  }
  
  .App {
    text-align: center;
  }
  
 
  /* Section styles */
  section {
    padding: 40px 10%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin: 20px auto;
    max-width: 1200px;
  }
  
  section h2 {
    font-size: 1.8em;
    margin-top: 0;
    color: #333;
  }
  
  section h3 {
    font-size: 1.4em;
    margin-top: 20px;
    color: #555;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
  }
  
  section ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
  }
  
  section ul ul {
    list-style-type: circle;
    padding-left: 20px;
  }
  
  section p {
    margin: 15px 0;
    line-height: 1.6;
  }
  
  /* Specific section styling */
  .about-expertise,
  .consulting-offerings,
  .our-team,
  .annexure {
    background-color: #ffffff;
  }
  
  /* Footer styles */
  .App-footer {
    background-color: #1e1e1e;
    color: #fff;
    padding: 15px 0;
    text-align: center;
    border-top: 3px solid #333;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .App-footer p {
    margin: 0;
  }