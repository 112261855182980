.App-header {
    background-color: #000;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .header-content h1 {
    color: #fff;
  }
  
  .header-content p {
    color: #ccc;
  }
  
  .contact-info p {
    color: #bbb;
  }